import React from 'react';
import './RowCta.css';

// import banImg from '../../assets/lapiazzetta.jpg';

function MenuTop() {
  return (
    <div className="ctaWrapper">
     <div className="accroche">
     <div className='accrocheTel'>Commande uniquement par téléphone au<br />03 88 75 68 40</div> <br /><br />
     Appelez-nous pour en savoir plus sur notre service livraison/ à emporter. 
     </div>
    </div>
  );
}

export default MenuTop;
