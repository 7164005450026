// Importer les dépendances nécessaires
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet'; /** pour intégrer des elements entete Html */


import NavBar from './components/NavBar/NavBar';
import FooterWrapper from './components/FooterWrapper/FooterWrapper';

import Home from './Home';
import Carte from './Carte';
import Livraison from './Livraison';
import Qualite from './Qualite';
// import About from './components/About';
// import Contact from './components/Contact';
 import NotFound from './NotFound';

// Définir le composant principal de l'application
const App = () => {
  return (
    <>  
     <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=family=Bad+Script&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Playfair+Display:ital,wght@0,400;0,500;0,600;1,400;1,500&display=swap" rel="stylesheet" />
      </Helmet>
      <header>
      <NavBar />
      </header>
      <div className="contentWrapper">
    <Router>
     
        {/* Mettre en place le système de routage */}
        <Routes>
          {/* Définir les routes et les composants correspondants */}
          <Route path="/" element={<Home />}></Route>
          <Route path="/carte-la-piazetta/" element={<Carte />}></Route>
          <Route path="/livraison/" element={<Livraison />}></Route>
          <Route path="/qualite/" element={<Qualite />}></Route>

          {/* Route par défaut pour gérer les URL non reconnues */}
          { <Route element={NotFound} /> }
        </Routes>

    </Router>
    </div>
      <footer>
      <FooterWrapper />
      <br />
      <div className="sign">&copy;{new Date().getFullYear()} La Piazzetta - <a href="https://www.atoneo.com" target='_blank' rel="noopener noreferrer"> Développement de sites reactJs - Agence Atoneo</a></div>
      </footer>
    </>
  );
};

export default App;
