import React from 'react';
import './BoxContainer.css';

function BoxContainer({ children, boxWidth = 100, boxPadding = 0, boxAlign='left' }) {
    const boxStyle = {
      width: `${boxWidth}%`,
      padding: `${boxPadding}px`,
      textAlign : boxAlign,
    };
  
    return (
      <div className= "BoxContainer" style={boxStyle}>
        {children}
      </div>
    );
  }
  
  export default BoxContainer;