import React from 'react';
import './BoxedWrapper.css';

function BoxedWrapper({ children, boxPadding = 0, boxAlign='left' }) {
    const boxStyle = {
      padding: `${boxPadding}px`,
      textAlign : boxAlign,
    };
  
    return (
      <div className= "BoxedWrapper" style={boxStyle}>
        <div>
        {children}
        </div>
      </div>
    );
  }
  
  export default BoxedWrapper;