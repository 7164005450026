import React from 'react';

const NotFound = () => {
  return (
    <div>
      <h1>Page d'accueil</h1>
      <p>Bienvenue sur la page d'accueil de mon application !</p>
    </div>
  );
};

export default NotFound;
