import React from 'react';
import './Ban.css';

// import banImg from '../../assets/lapiazzetta.jpg';

function MenuTop() {
  return (
    <div className="banWrapper">
     <div className="accroche">
     <h1>Bienvenuti a tutti !</h1> <br /><br />
        A La Piazzetta, nous offrons une cuisine italienne authentique, nos pizzas napolitaines, des pâtes savoureuses au gré des saisons et des plats du jour variés en semaine.<br /><br />

        Notre service de livraison dans le Kochersberg (32 communes) est garantie par nos soins et vous pouvez également commander vos plats à emporter en ligne ou sur place.
     </div>
    </div>
  );
}

export default MenuTop;
