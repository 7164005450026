import React from 'react';

import './FlexRow.css';

function FlexRow({ direction = 'row', children, gap = '10' }) {
    const flexStyle = {
      display: 'flex',
      flexDirection: direction,
      gap: `${gap}px`,
    };
  
    return (
      <div className= "FlexRow" style={flexStyle}>
        {children}
      </div>
    );
  }
  
  export default FlexRow;