import React from 'react';
import { Helmet } from 'react-helmet'; /** pour intégrer des elements entete Html */
import BoxedWrapper from './components/BoxedWrapper/BoxedWrapper';
import RowCta from './components/RowCta/RowCta';

import carte from './../src/assets/fly.jpg';

const Carte = () => {
  return (
    <>
    <Helmet>
         <title>La Carte - La piazzetta - Restaurant Italien</title>
        <meta name="description" content="Votre pizzeria à Emporter au kochersberg."></meta>
      </Helmet>
      <BoxedWrapper>
      <h1>Notre carte</h1>
      <img src={carte} alt="Notre carte la Piazzetta Kochersberg"/>

      </BoxedWrapper>
      <RowCta />
    </>
  );
};

export default Carte;
