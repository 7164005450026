import React from 'react';
import Iframe from 'react-iframe'
import './Home.css';

import Ban from './components/Ban/Ban';
import FlexRow from './components/FlexRow/FlexRow';
import BoxContainer from './components/BoxContainer/BoxContainer';
import Card from './components/Card/Card';
import RowCta from './components/RowCta/RowCta';

import notreCarte from './assets/lapiazzetta.jpg';
import notreLivraison from './assets/livraison.jpeg';
import notreQualite from './assets/pate.jpg';
import logo from './piazzetta.jpeg';

import { Helmet } from 'react-helmet'; /** pour intégrer des elements entete Html */





function Home() {
  return (
    <div className="Home">
        <Helmet>
         <title>La piazzetta - Restaurant Italien</title>
        <meta name="description" content="Votre pizzeria à Emporter au kochersberg."></meta>
      </Helmet>
      <Ban />
      
      <br />
      <FlexRow direction="row" gap="10">
      <BoxContainer boxWidth="80" boxPadding={20} boxAlign='center'>
      <img src={logo} alt="Piazzetta" style={{width:250}}/>
      <h1>La piazzetta - Cuisine italienne</h1>
      <h2>Qui sommes-nous ?</h2>

        La Piazzetta, est le lieu où respire l'Italie en plein coeur du Kochersberg. Les 2 chefs, après 20 ans d'amitié, ont décidé de mettre en commun leurs passions : la cuisine et l'Italie. C'est comme cela qu'est née La Piazzetta.<br/><br/>

        Filipe et Franck ont à coeur de faire de ce lieu une adresse conviviale où y règne une vraie ambiance "Dolce Vita". Y est servie une cuisine authentique valorisant les produits italiens. <br/><br/>

        La spécialité ? Les pizzas à pâte épaisse de Naples !<br/><br/>
    
    <FlexRow gap="30">
    <Card
        colPourc='33'
        image={notreCarte}
        link="/carte-la-piazetta/"
        title="Notre carte"
        text="Retrouvez notre selection de plats et pizza"
      />
      <Card
        colPourc='33'
        image={notreLivraison}
        link="/livraison/"
        title="Service de livraison"
        text="A La Piazzetta, nous vous proposons de profiter de notre savoir-faire sans bouger de chez vous ! "
      />
      <Card
        colPourc='33'
        image={notreQualite}
        link="/qualite/"
        title="La qualité"
        text="Nous proposons des plats délicieux et variés, préparés avec passion, entièrement faits maison et à base de produits frais."
      />
    </FlexRow>
      </BoxContainer>
      <BoxContainer boxWidth="20" boxAlign='center'>
        <h2>Notre actu via Facebook</h2>
        <Iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100093610638975&tabs=timeline&width=380&height=830&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=422302658652292" width="380" height="830" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></Iframe>
      </BoxContainer>
      </FlexRow>

      <RowCta />     
    </div>
  );
}

export default Home;
