import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import './Card.css';


export default function MediaCard({ image, link, title, text, colPourc }) {
  // Calcul du pourcentage en fonction de col
  const cardWidth = `${colPourc}%`;
  console.log(cardWidth)

  return (
    <Card sx={{ maxWidth: '100%', width: cardWidth }}>
      <CardActionArea>
      <a href={link}>
      <CardMedia
        sx={{ height: 250 }}
        image={image} // Utilisation de l'argument image
        title={title} // Utilisation de l'argument title
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title} {/* Utilisation de l'argument title */}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {text} {/* Utilisation de l'argument text */}
        </Typography>
      </CardContent>
      <CardActions className='blocAction'>
        <Button size="small">En savoir plus</Button>
      </CardActions>
      </a>
      </CardActionArea>
    </Card>
  );
}

