import React from 'react';
import { Helmet } from 'react-helmet'; /** pour intégrer des elements entete Html */
import BoxedWrapper from './components/BoxedWrapper/BoxedWrapper';
import RowCta from './components/RowCta/RowCta';

import zone from './../src/assets/zone.png';

const Carte = () => {
  return (
    <>
    <Helmet>
         <title>La livraison - La piazzetta - Restaurant Italien</title>
        <meta name="description" content="Votre pizzeria à Emporter au kochersberg."></meta>
      </Helmet>
      <BoxedWrapper>
      <h1>Livraison rapide et Écologique</h1>
        A La Piazzetta, nous sommes passionnés par la cuisine et nous nous efforçons de mettre à votre disposition des plats faits maison à base de produits locaux. Notre objectif est de vous offrir un service de qualité afin que vous puissiez déguster des plats sains et savoureux.
        <br /><br />
        Notre service de livraison vous permet de profiter de ce voyage culinaire à domicile ou au bureau.
        <br /><br />
        Nous nous engageons à vous fournir des produits frais et de qualité, à travers un circuit court, pour votre plus grand plaisir !

        <h2>Notre service de livraison et vente à emporter</h2>
        A La Piazzetta, nous offrons des solutions de livraison personnalisées aux entreprises et aux particuliers dans le Kochersberg et ses 32 communes environnantes.
        <br /><br />
        Notre service de livraison est assuré par nos soins.
        <br /><br />
        Vous pouvez commander et nous assurons la livraison midi et soir !
        <br /><br />
        TARIF UNIQUE DE LIVRAISON : 3,50€
        <br /><br />
        LIVRAISON OFFERTE DES 45€
        <br /><br />
        Les 32 communes que nous desservons : 
        <br /><br />
        <img src={zone} alt="Zone de livraison la Piazzetta Kochersberg"/>

      </BoxedWrapper>
      <br />
        <RowCta />
    </>
  );
};

export default Carte;
